import { Injectable } from '@angular/core';
import { UrlEnum } from '../enum/url.enum';
import { UtilitiesService } from './utilities/utilities.service';
import { HttpRequestService } from './http-request/http-request.service';
import { map, Observable } from 'rxjs';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from '../enum/approval-category.enum';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  private approvalUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/approval`;
  private approvalCategoryUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/approval-category`;

  public static approval_category = new Map<ApprovalCategoryEnum, string>();

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {
    this.getAllApprovalCategory();
  }

  private getAllApprovalCategory(): void {
    this.httpRequestService
      .get(`${this.approvalCategoryUrl}/get-all`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          const map = new Map<ApprovalCategoryEnum, string>();
          x.forEach((cat: any) => map.set(cat.category_key, cat.id));
          return map;
        })
      )
      .subscribe((map: Map<ApprovalCategoryEnum, string>) => {
        ApprovalService.approval_category = map;
      });
  }

  public getAllRequest(category: ApprovalCategoryEnum): Observable<any> {
    return this.httpRequestService.get(`${this.approvalUrl}/get-all`).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) => {
        return x
          .filter((item: any) => item.approval_category === category)
          .sort((a: any, b: any) => {
            return (
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
            );
          })
          .map((item: any) => {
            item.attribute = item.attribute ? JSON.parse(item.attribute) : null;
            item.searchValue =
              item.attribute?.title +
              '_' +
              item.attribute?.subtitle +
              '_' +
              item.attribute?.ngo_name +
              '_' +
              item.status;
            return item;
          });
      })
    );
  }

  public makeRequest(body: any): Observable<any> {
    return this.httpRequestService
      .post(`${this.approvalUrl}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public takeApprovalAction(
    id: string,
    action: ApprovalStatusEnum
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.approvalUrl}/update/${id}`, { status: action })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
